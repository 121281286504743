export const PlaceOrderIcon = props => (
  <svg
    width={40}
    height={40}
    viewBox='0 0 40 40'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      fill='currentColor'
      d='M17.931 30.863H6.942V13.768h24.421v4.885a1.22 1.22 0 1 0 2.442 0V5.22A1.22 1.22 0 0 0 32.585 4H5.72A1.22 1.22 0 0 0 4.5 5.221v26.863a1.22 1.22 0 0 0 1.221 1.221h12.21a1.22 1.22 0 1 0 0-2.442Zm-1.22-23.2a1.221 1.221 0 1 1-.001 2.443 1.221 1.221 0 0 1 0-2.443Zm-3.664 0a1.221 1.221 0 1 1 0 2.443 1.221 1.221 0 0 1 0-2.443Zm-3.663 0a1.221 1.221 0 1 1 0 2.443 1.221 1.221 0 0 1 0-2.443Z'
    />
    <path
      fill='currentColor'
      d='m34.746 30.079-2.172.733c-.6.2-1.08.666-1.28 1.279l-.732 2.172c-.627 1.879-3.265 1.839-3.851-.04l-2.465-7.928c-.48-1.573.972-3.039 2.531-2.546l7.942 2.466c1.866.586 1.892 3.238.027 3.864Z'
    />
    <circle cx='12.152' cy='18.652' r='2' fill='currentColor' />
    <circle cx='19.152' cy='18.652' r='2' fill='currentColor' />
    <circle cx='26.152' cy='18.652' r='2' fill='currentColor' />
    <circle cx='12.152' cy='25.652' r='2' fill='currentColor' />
    <circle cx='19.152' cy='25.652' r='2' fill='currentColor' />
  </svg>
);
