import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {ReviewsSwiper} from 'src/componentsNext/ReviewsSwiperNext';

import {ReviewsFirstCard} from 'src/componentsNext/ReviewsSwiperNext/components/ReviewsFirstCard';
import {SectionHeader} from 'src/views/GameItemsNext/components/SectionHeader';

const useStyles = makeStyles(theme => ({
  reviewsSection: {
    margin: theme.spacing(9, 0, 0),
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0, 0)
    }
  },
  reviewsSectionContent: {
    // maxWidth: 1300 + theme.spacing(4),
    // width: '100%',
    width: 'calc(100% - 10%)',
    overflow: 'visible',
    margin: '0 auto',
    padding: theme.spacing(0, 2, 7.5, 2),

    [theme.breakpoints.down('lg')]: {
      maxWidth: 1300 + theme.spacing(4),
      width: '100%'
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2, 7.5, 2)
    }
  }
}));

export const ReviewsSection = ({testimonials}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className={classes.reviewsSection}>
      <div className={classes.reviewsSectionContent}>
        <SectionHeader title='What Our Customers Say' mb={isDownSm ? 32 : 40} />
        <ReviewsSwiper
          testimonials={testimonials}
          customSlide={<ReviewsFirstCard />}
        />
      </div>
    </div>
  );
};

ReviewsSection.propTypes = {
  testimonials: PropTypes.array.isRequired
};
