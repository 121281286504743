import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Fragment} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';

import {DoubleArrowRightIcon} from 'src/componentsNext/icons/arrows/DoubleArrowRightIcon';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const useStyles = makeStyles(theme => ({
  steps: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3),
    border: `1px solid ${theme.palette.colors.borderSection}`,
    borderRadius: 4,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.colors.section,
    boxShadow:
      '0px 4px 4px 0px rgba(34, 34, 34, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.14)'
  },
  step: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),

    '& svg': {
      color: theme.palette.success.main
    }
  },
  arrowsIcon: {
    '&:last-child': {
      display: 'none'
    }
  },
  stepsMobile: {
    display: 'grid',
    gap: theme.spacing(2),
    justifyContent: 'center',
    gridAutoColumns: '1fr'
  },
  stepMobile: {
    // width: 328,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: 'max-content max-content',
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.colors.section,
    border: `1px solid ${theme.palette.colors.borderSection}`,
    borderRadius: 4,
    boxShadow:
      '0px 4px 4px 0px rgba(34, 34, 34, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.14)',

    '& svg': {
      color: theme.palette.success.main
    }
  },
  stepMobileText: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    color: theme.palette.common.white
  },
  orderMobile: {
    color: theme.palette.success.main,

    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1
  }
}));

export const Steps = ({steps}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (isDownSm) {
    return (
      <div className={classes.stepsMobile}>
        {steps.map((step, index) => {
          const Icon = step.icon;
          return (
            <Fragment key={index}>
              <div className={classes.stepMobile}>
                <div className={classes.stepMobileText}>
                  <Typography classes={{root: classes.orderMobile}}>
                    {index + 1}
                  </Typography>
                  <Typography style={extendedTypography.subtitleSemiboldMobile}>
                    {step.text}
                  </Typography>
                </div>
                <Icon />
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.steps}>
      {steps.map((step, index) => {
        const Icon = step.icon;
        return (
          <Fragment key={index}>
            <div className={classes.step}>
              <Icon />
              <Typography style={extendedTypography.h5MediumDesktop}>
                {step.text}
              </Typography>
            </div>
            <DoubleArrowRightIcon
              width={32}
              height={32}
              className={classes.arrowsIcon}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.array.isRequired
};
