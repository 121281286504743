import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {Steps} from './Steps';

import {SectionHeader} from 'src/views/GameItemsNext/components/SectionHeader';

export const useStyles = makeStyles(theme => ({
  howToBuySection: {
    margin: [theme.spacing(4, 'auto', 0, 'auto')],
    maxWidth: 1300 + theme.spacing(4),
    width: '100%',
    padding: theme.spacing(6, 2, 0, 2),

    [theme.breakpoints.down('sm')]: {
      margin: [theme.spacing(3, 'auto', 0, 'auto')],
      padding: [theme.spacing(6, 2, 0, 2)]
    }
  }
}));

export const StepsSection = ({title, subtitle, steps}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className={classes.howToBuySection}>
      <SectionHeader
        title={title}
        subtitle={!isDownSm ? subtitle : ''}
        mb={isDownSm ? 20 : 32}
      />
      <Steps steps={steps} />
    </div>
  );
};
