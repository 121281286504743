export const AddIcon = props => (
  <svg
    width={32}
    height={32}
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path
      stroke='currentColor'
      strokeLinecap='square'
      strokeLinejoin='round'
      strokeMiterlimit='10'
      strokeWidth='3'
      d='M24.666 16H7.333M15.999 24.667V7.334'
    />
  </svg>
);
