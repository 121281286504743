import {makeStyles} from '@material-ui/core/styles';
import {range} from 'lodash/util';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';

import {TrustpilotStarIcon} from 'src/componentsNext/icons/trustpilot/TrustpilotStartIcon';
import {TrustpilotLogoIcon} from 'src/componentsNext/icons/trustpilot/TrustpilotLogoIcon';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

const useStyles = makeStyles(theme => ({
  slide: {
    height: 224,
    maxHeight: '-webkit-fit-content',
    display: 'grid',
    gridTemplateRows: '168px 1fr',
    boxSizing: 'border-box',
    // padding: theme.spacing(2),
    textAlign: 'left',
    borderRadius: 4,
    backgroundColor: theme.palette.colors.coupon,
    // background:
    //   'left / cover url("/images/imagesNext/reviewsSwiperSlideBg.png") no-repeat fixed',

    [theme.breakpoints.down('sm')]: {
      height: 190,
      gridTemplateRows: '138px 1fr',
      borderRadius: 6
    }
  },
  content: {
    padding: theme.spacing(2),
    display: 'grid',
    // gap: theme.spacing(),
    justifyContent: 'center',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    background:
      'left / cover url("/images/imagesNext/reviewsSwiperSlideBg.png") no-repeat fixed',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5)
    }
  },
  stars: {
    display: 'grid',
    gap: theme.spacing(0.7),
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    margin: '0 auto',
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(0.6),
      paddingBottom: theme.spacing(1.5)
    }
  },
  textHighlighted: {color: theme.palette.secondary.main},
  text: {
    color: '#D1D1D1'
  },
  bottomIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%'
  },
  topText: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1)
    }
  },
  link: {
    '&:hover': {
      // opacity: '0.85'
    }
  }
}));

export const ReviewsFirstCard = () => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className={classes.slide}>
      <div className={classes.content}>
        <div className={classes.stars}>
          {range(0, 5).map(index => (
            <TrustpilotStarIcon
              color='#219653'
              key={index}
              width={isDownSm ? 24 : 32}
              height={isDownSm ? 24 : 32}
            />
          ))}
        </div>
        <div className={classes.topText}>
          <Typography
            component='span'
            classes={{root: classes.textHighlighted}}
            style={
              isDownSm
                ? extendedTypography.h5SemiboldMobile
                : extendedTypography.h3Desktop
            }
          >
            Excellent 4.91
          </Typography>
          <Typography
            component='span'
            classes={{root: clsx(classes.text, classes.textTop)}}
            style={
              isDownSm
                ? extendedTypography.h5SemiboldMobile
                : extendedTypography.h4RegularDesktop
            }
          >
            {' '}
            out of 5
          </Typography>
        </div>
        <div>
          <Typography
            component='span'
            style={
              isDownSm
                ? extendedTypography.h4SemiboldMobile
                : extendedTypography.h3Desktop
            }
            classes={{root: classes.text}}
          >
            16,000+{' '}
          </Typography>
          <Typography
            component='span'
            classes={{root: classes.text}}
            style={
              isDownSm
                ? extendedTypography.h5SemiboldMobile
                : extendedTypography.h4RegularDesktop
            }
          >
            Reviews
          </Typography>
        </div>
      </div>
      <Link
        href='https://www.reviews.io/company-reviews/store/probemas-com'
        target='_blank'
        rel='noopener noreferrer'
        classes={{root: classes.link}}
      >
        <div className={classes.bottomIcon}>
          <TrustpilotLogoIcon
            width={isDownSm ? 128 : 167}
            height={isDownSm ? 28 : 36}
            color='#000'
          />
        </div>
      </Link>
    </div>
  );
};
