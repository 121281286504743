export const CompletePaymentIcon = props => (
  <svg
    width={40}
    height={40}
    viewBox='0 0 40 40'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <rect width='34' height='10' x='2.5' y='5' fill='currentColor' rx='1.75' />
    <path
      fill='currentColor'
      d='M31.5 25.5A6.498 6.498 0 0 0 25 32a6.37 6.37 0 0 0 .942 3.347A6.45 6.45 0 0 0 31.5 38.5a6.45 6.45 0 0 0 5.557-3.153A6.37 6.37 0 0 0 38 32c0-3.591-2.909-6.5-6.5-6.5Zm3.364 5.801-3.461 3.201a1.24 1.24 0 0 1-.83.325c-.308 0-.617-.113-.86-.357l-1.61-1.609a1.226 1.226 0 0 1 0-1.722 1.226 1.226 0 0 1 1.723 0l.78.78 2.6-2.405a1.227 1.227 0 0 1 1.723.065c.455.487.422 1.251-.065 1.722Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M2.5 18.75c0-.966.784-1.75 1.75-1.75h30.5c.967 0 1.75.784 1.75 1.75v4.588a9.955 9.955 0 0 0-5-1.338c-5.523 0-10 4.477-10 10 0 .685.069 1.354.2 2H4.25a1.75 1.75 0 0 1-1.75-1.75v-13.5Zm5 8.75A1.5 1.5 0 0 1 9 26h4a1.5 1.5 0 0 1 0 3H9a1.5 1.5 0 0 1-1.5-1.5Z'
      clipRule='evenodd'
    />
  </svg>
);
