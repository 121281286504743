import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import {FaqNext} from 'src/componentsNext/FaqNext/FaqNext';
import {LoyaltyBannerNext} from 'src/componentsNext/LoyaltyBannerNext';

import {SectionHeader} from 'src/views/GameItemsNext/components/SectionHeader';

const useStyles = makeStyles(theme => ({
  faqSectionWithBanner: {
    maxWidth: 1300 + theme.spacing(4),
    width: '100%',
    padding: theme.spacing(0, 2),
    margin: [theme.spacing(9, 'auto', 0, 'auto')],

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2)
    }
  },
  faqSection: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5)
    }
  },
  faqSectionContent: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(4),

    '&.isBanner': {
      gridTemplateColumns: 'max-content 1fr'
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',

      '&.isBanner': {
        gridTemplateColumns: '1fr'
      }
    }
  },
  bannerMobile: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(9)
  }
}));

export const FaqSection = ({page, isBanner = false}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div
      className={clsx(
        classes.faqSectionWithBanner,
        !isBanner && classes.faqSection
      )}
    >
      {isBanner && isDownSm && (
        <div className={classes.bannerMobile}>
          <LoyaltyBannerNext />
        </div>
      )}

      <SectionHeader title='Frequently Asked Questions' />

      <div className={clsx(classes.faqSectionContent, isBanner && 'isBanner')}>
        {isBanner && !isDownSm && <LoyaltyBannerNext />}
        <div>
          <FaqNext items={page.faqItems.edges} />
        </div>
      </div>
    </div>
  );
};

FaqSection.propTypes = {
  page: PropTypes.object,
  isBanner: PropTypes.bool
};
