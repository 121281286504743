import {memo} from 'react';

export const TrustpilotStarIcon = memo(props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={25}
    height={25}
    viewBox='0 0 25 25'
    fill='none'
    {...props}
  >
    <rect width='25' height='25' y='.5' fill='currentColor' rx='3' />
    <path
      fill='#fff'
      d='m12.586 16.597 3.222-.852 1.359 4.181-4.581-3.329Zm7.43-5.407h-5.7L12.56 5.784l-1.758 5.406h-5.7l4.608 3.356-1.758 5.407 4.608-3.33 2.85-2.077 4.607-3.356Z'
    />
  </svg>
));
