export const MessageChatIcon = props => (
  <svg
    width={40}
    height={40}
    viewBox='0 0 40 40'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <path
      fill='currentColor'
      d='M27.165 3.333h-20a3.333 3.333 0 0 0-3.333 3.333v21.667a1.667 1.667 0 0 0 1.033 1.533A1.4 1.4 0 0 0 5.5 30a1.669 1.669 0 0 0 1.183-.484l6.167-6.183h14.316A3.333 3.333 0 0 0 30.5 20V6.666a3.333 3.333 0 0 0-3.334-3.333Z'
    />
    <path
      fill='currentColor'
      d='M35.501 36.666a1.665 1.665 0 0 1-1.183-.483L28.151 30H13.835a1.667 1.667 0 0 1 0-3.334h15a1.667 1.667 0 0 1 1.183.484l3.817 3.833V11.666h-5a1.667 1.667 0 0 1 0-3.333h5a3.334 3.334 0 0 1 3.333 3.333V35a1.667 1.667 0 0 1-1.033 1.533 1.4 1.4 0 0 1-.634.133Z'
    />
  </svg>
);
