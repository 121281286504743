import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useState} from 'react';
import dynamic from 'next/dynamic';

import {RemoveIcon} from 'src/componentsNext/icons/actions/RemoveIcon';
import {AddIcon} from 'src/componentsNext/icons/actions/AddIcon';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

const RichTextContent = dynamic(
  () =>
    import('src/components/RichTextContent').then(mod => mod.RichTextContent),
  {ssr: false}
);

const useStyles = makeStyles(theme => ({
  faqBox: {
    display: 'grid',
    gap: theme.spacing(2),
    marginTop: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(3)
    }
  },
  panel: {
    backgroundColor: theme.palette.colors.blue[800],
    backdropFilter: 'blur(10px)',
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.blue[600]}`,
    willChange: 'transition',

    '&:before': {
      display: 'none'
    },

    '&.Mui-expanded': {
      margin: 0,
      backgroundColor: theme.palette.colors.blue[700],
      transition: 'all .2s'
    },

    [theme.breakpoints.down('xs')]: {
      borderRadius: 8,
      border: 'none'
    }
  },

  content: {
    margin: 0,
    willChange: 'transition',

    '&.Mui-expanded': {
      margin: 0,

      '& > p': {
        color: theme.palette.secondary.main
      }
    }
  },
  summary: {
    padding: theme.spacing(2, 2.5),
    willChange: 'transition',

    [theme.breakpoints.down('sm')]: {
      minHeight: 60,
      padding: theme.spacing(2, 6, 2, 1.5)
    },

    '&.Mui-expanded': {
      minHeight: 62,
      padding: theme.spacing(2, 2.5),

      [theme.breakpoints.down('sm')]: {
        minHeight: 60,
        padding: theme.spacing(2, 6, 2, 1.5)
      }
    }
  },
  expandIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,

    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(1),

      '& svg': {
        width: 24,
        height: 24
      }
    }
  },
  details: {
    willChange: 'transition',
    display: 'block',
    position: 'relative',
    padding: theme.spacing(2, 2.5),
    margin: 0,
    ...extendedTypography.body1RegularDesktop,
    whiteSpace: 'pre-wrap',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: 'calc(100% - 40px)',
      height: 1,
      margin: '0 auto',
      backgroundColor: theme.palette.colors.blue[600]
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1.5, 2, 1.5),
      ...extendedTypography.body1RegularMobile,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: -4,
        width: 'calc(100% - 24px)',
        height: 1,
        margin: '0 auto',
        backgroundColor: theme.palette.colors.blue[600]
      }
    }
  },
  question: {
    ...extendedTypography.h5MediumDesktop,
    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.h5MediumMobile
    }
  }
}));

function stripHtml(text) {
  const regex = /(<([^>]+)>)/gi;
  return text.replace(regex, '');
}

export const FaqNext = ({items}) => {
  const [expandedItem, setExpandedItem] = useState(false);
  const classes = useStyles();

  const handleChange = (e, item) => {
    setExpandedItem(expandedItem !== item ? item : null);
  };

  return (
    <div className={classes.faqBox}>
      {items.map(({node: item}) => (
        <Accordion
          square
          classes={{
            expanded: classes.panelExpanded,
            root: classes.panel
          }}
          key={item.id}
          expanded={expandedItem === item.id}
          onChange={e => handleChange(e, item.id)}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={expandedItem === item.id ? <RemoveIcon /> : <AddIcon />}
            classes={{
              root: classes.summary,
              content: classes.content,
              expandIcon: classes.expandIcon
            }}
            IconButtonProps={{
              edge: 'start',
              disableRipple: true
            }}
          >
            <Typography classes={{root: classes.question}}>
              {stripHtml(item.question)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {/*{stripHtml(item.answer)}*/}
            <RichTextContent htmlContent={item.answer} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

FaqNext.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string
    })
  )
};
